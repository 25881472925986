<template>
  <div class="modal-wrapper">
    <div class="modal-overlay" @click="closeModal"></div>

    <section
      class="expert-response-modal modal card shadow"
      role="dialog"
      aria-labelledby="expertResponseDetailsModalTitle"
    >
      <header class="flex-row flex-align-spaced flex-center-v flex-nowrap">
        <h2 id="expertResponseDetailsModalTitle">Évaluer de la réponse</h2>
        <button class="button--icon" @click="closeModal">
          <svg
            role="img"
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="14"
            fill="none"
            viewBox="0 0 14 14"
          >
            <title>Fermer</title>
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M13.7071 0.292893C14.0976 0.683417 14.0976 1.31658 13.7071 1.70711L1.70711 13.7071C1.31658 14.0976 0.683417 14.0976 0.292893 13.7071C-0.0976311 13.3166 -0.0976311 12.6834 0.292893 12.2929L12.2929 0.292893C12.6834 -0.0976311 13.3166 -0.0976311 13.7071 0.292893Z"
              clip-rule="evenodd"
            />
            <path
              fill="currentColor"
              fill-rule="evenodd"
              d="M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L13.7071 12.2929C14.0976 12.6834 14.0976 13.3166 13.7071 13.7071C13.3166 14.0976 12.6834 14.0976 12.2929 13.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </header>

      <form @submit.prevent="handleSubmit">
        <div
          class="fieldset"
          v-if="user_is_expert || user_is_expert_ext || user_is_coordinator"
        >
          <div>
            <span class="label">Grade de preuve (EBM) :</span>
            <div class="input-group indent-labels">
              <label>
                <input
                  type="radio"
                  name="ebm_proof_grade"
                  value=""
                  v-model="questionDetails.ebm_proof_grade"
                /><span class="caption">Indéfini</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="ebm_proof_grade"
                  value="a"
                  v-model="questionDetails.ebm_proof_grade"
                /><span class="caption">A – Preuve scientifique établie</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="ebm_proof_grade"
                  value="b"
                  v-model="questionDetails.ebm_proof_grade"
                /><span class="caption">B – Présomption scientifique</span>
              </label>
              <label>
                <input
                  type="radio"
                  name="ebm_proof_grade"
                  value="c"
                  v-model="questionDetails.ebm_proof_grade"
                /><span class="caption"
                  >C – Faible niveau de preuve scientifique</span
                >
              </label>
              <label>
                <input
                  type="radio"
                  name="ebm_proof_grade"
                  value="d"
                  v-model="questionDetails.ebm_proof_grade"
                /><span class="caption">D – Accord professionnel</span>
              </label>
            </div>
          </div>

          <div>
            <span class="label">Niveau de recommandation :</span>
            <div class="input-group indent-labels">
              <label>
                <input
                  type="radio"
                  name="recommendation_level"
                  value=""
                  v-model="questionDetails.recommendation_level"
                /><span class="caption">Indéfini</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="recommendation_level"
                  value="fort"
                  v-model="questionDetails.recommendation_level"
                /><span class="caption">Fort</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="recommendation_level"
                  value="intermediaire"
                  v-model="questionDetails.recommendation_level"
                /><span class="caption">Intermédiaire</span>
              </label>

              <label>
                <input
                  type="radio"
                  name="recommendation_level"
                  value="faible"
                  v-model="questionDetails.recommendation_level"
                /><span class="caption">Faible</span>
              </label>
            </div>
          </div>
        </div>

        <div class="input-group-controls flex-row flex-align-right">
          <button class="button--primary" type="submit">
            <svg
              class="icon"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              width="22"
              height="22"
              fill="none"
              viewBox="0 0 22 22"
            >
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M21.7071 0.292921C22.0976 0.683445 22.0976 1.31661 21.7071 1.70713L10.7071 12.7071C10.3166 13.0977 9.68342 13.0977 9.2929 12.7071C8.90237 12.3166 8.90237 11.6834 9.2929 11.2929L20.2929 0.292921C20.6834 -0.0976032 21.3166 -0.0976032 21.7071 0.292921Z"
                clip-rule="evenodd"
              />
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M21.7071 0.292921C21.9787 0.564559 22.0708 0.967791 21.9439 1.33038L14.9439 21.3304C14.8084 21.7174 14.4504 21.9825 14.0408 21.9992C13.6311 22.0159 13.2527 21.7808 13.0862 21.4062L9.2424 12.7576L0.593867 8.91384C0.219186 8.74731 -0.0158775 8.36894 0.000836285 7.95926C0.0175501 7.54959 0.282654 7.19162 0.669655 7.05617L20.6697 0.0561695C21.0322 -0.0707359 21.4355 0.0212832 21.7071 0.292921ZM3.71108 8.11063L10.4061 11.0862C10.6324 11.1868 10.8133 11.3677 10.9138 11.5939L13.8894 18.289L19.37 2.63L3.71108 8.11063Z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="caption">Enregistrer</span>
          </button>
        </div>
      </form>
    </section>
  </div>
</template>

<style lang="scss" scoped>
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";

.expert-response-modal {
  width: 50rem;
  max-width: 100%;

  .fieldset {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    & > * {
      margin: 0;
      padding: 1rem 0;
      flex-basis: 20rem;
      flex-grow: 1;
      width: 50%;

      .label + .input-group {
        margin-top: 1rem;
      }
    }
  }

  @media all and (max-width: 768px) {
    .input-group-controls {
      justify-content: center;
    }
  }
}
</style>

<script>
export default {
  name: "expertResponseDetailsModal",
  props: ["user", "question"],

  data: function() {
    return {
      questionDetails: {},
    };
  },

  mounted() {
    // form
    this.questionDetails = {
      ebm_proof_grade: this.question.ebm_proof_grade,
      recommendation_level: this.question.recommendation_level,
    };
  },

  computed: {
    // Is the user a coordinator? (role 3)
    user_is_coordinator: function() {
      return this.user.roles.indexOf(3) !== -1;
    },

    // Is the user an expert? (role 2)
    user_is_expert: function() {
      return this.user.roles.indexOf(2) !== -1;
    },

    // Is the user an expert extended? (role 2)
    user_is_expert_ext: function() {
      return this.user.roles.indexOf(5) !== -1;
    },
  },

  methods: {
    closeModal: function() {
      this.$emit("modalClose");
    },

    // submit event to other components
    handleSubmit: function(event) {
      this.$emit("submit", this.questionDetails);
    },
  },
};
</script>

<template>
  <article class="question" v-if="question">
    <div class="site-wrapper rythm-v-l">
      <header class="page-header">
        <h1>
          {{ question.title }}
          <span v-if="!user_is_regular && isQuestionStarred" class="question-alt-status alt-status--starred" title="Question favorite">★</span>
          <span v-if="isQuestionUrgent" class="question-alt-status" title="Question urgente">❗</span>
        </h1>
      </header>

      <section class="section--question rythm-v">
        <header>
          <h2 class="question-type">{{ typeCaption[question.type] }}</h2>
          <span :class="`tag--${question.status}`">{{ statusCaption[question.status] }}</span>
        </header>

        <div class="toolbar">
          <router-link to="/dashboard" class="button--outline">
            <svg role="img" class="icon" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
              <path
                fill="currentColor"
                d="M7.29289 15.7071C7.68342 16.0976 8.31658 16.0976 8.70711 15.7071C9.09763 15.3166 9.09763 14.6834 8.70711 14.2929L3.41421 9L15 9C15.5523 9 16 8.55229 16 8C16 7.44772 15.5523 7 15 7L3.41421 7L8.70711 1.70711C9.09763 1.31658 9.09763 0.683418 8.70711 0.292894C8.31658 -0.0976312 7.68342 -0.0976312 7.29289 0.292894L0.292894 7.29289C-0.0976312 7.68342 -0.0976312 8.31658 0.292894 8.70711L7.29289 15.7071Z"
              />
            </svg>
            <span class="caption">Retour</span></router-link
          >

          <button :class="question.expert_id ? 'button--outline' : 'button--primary'" v-if="user_is_coordinator && question.status !== 'complete'" @click="assignUserModal()">
            <svg class="icon" role="img" xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="none" viewBox="0 0 20 16">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M1.17157 10.7716C1.92172 10.0214 2.93913 9.6 4 9.6H9.6C10.6609 9.6 11.6783 10.0214 12.4284 10.7716 13.1786 11.5217 13.6 12.5391 13.6 13.6V15.2C13.6 15.6418 13.2418 16 12.8 16 12.3582 16 12 15.6418 12 15.2V13.6C12 12.9635 11.7471 12.353 11.2971 11.9029 10.847 11.4529 10.2365 11.2 9.6 11.2H4C3.36348 11.2 2.75303 11.4529 2.30294 11.9029 1.85286 12.353 1.6 12.9635 1.6 13.6V15.2C1.6 15.6418 1.24183 16 .8 16 .358172 16 0 15.6418 0 15.2V13.6C0 12.5391.421428 11.5217 1.17157 10.7716zM6.8 1.6C5.47452 1.6 4.4 2.67452 4.4 4 4.4 5.32548 5.47452 6.4 6.8 6.4 8.12548 6.4 9.2 5.32548 9.2 4 9.2 2.67452 8.12548 1.6 6.8 1.6zM2.8 4C2.8 1.79086 4.59086 0 6.8 0 9.00914 0 10.8 1.79086 10.8 4 10.8 6.20914 9.00914 8 6.8 8 4.59086 8 2.8 6.20914 2.8 4zM16 4C16.4418 4 16.8 4.35817 16.8 4.8V9.6C16.8 10.0418 16.4418 10.4 16 10.4 15.5582 10.4 15.2 10.0418 15.2 9.6V4.8C15.2 4.35817 15.5582 4 16 4z"
                clip-rule="evenodd"
              />
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M12.8 7.2C12.8 6.75817 13.1582 6.4 13.6 6.4H18.4C18.8418 6.4 19.2 6.75817 19.2 7.2C19.2 7.64183 18.8418 8 18.4 8H13.6C13.1582 8 12.8 7.64183 12.8 7.2Z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="caption">Affecter</span>
          </button>

          <button class="button--outline" type="button" v-if="!user_is_regular" @click="toggleMailtoModal()">
            <span class="caption">Demande d'échange</span>
            <svg class="icon icon--envelope" role="img" aria-hidden="true" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M2.75 0C1.23122 0 0 1.23122 0 2.75V11.25C0 12.7688 1.23122 14 2.75 14H13.25C14.7688 14 16 12.7688 16 11.25V2.75C16 1.23122 14.7688 0 13.25 0H2.75ZM2.63758 1.50499C2.67462 1.50169 2.71211 1.5 2.75 1.5H13.25C13.2879 1.5 13.3254 1.50169 13.3624 1.50499L8.00001 6.24867L2.63758 1.50499ZM1.52111 2.52002C1.50725 2.59456 1.5 2.67144 1.5 2.75V11.25C1.5 11.9404 2.05964 12.5 2.75 12.5H13.25C13.9404 12.5 14.5 11.9404 14.5 11.25V2.75C14.5 2.67144 14.4928 2.59457 14.4789 2.52003L8.49694 7.81176C8.2132 8.06276 7.78682 8.06276 7.50308 7.81176L1.52111 2.52002Z"
                fill="currentColor"
              />
            </svg>
          </button>

          <button v-if="canCloseQuestion" type="button" :class="this.question.status === 'ongoing' ? 'button--primary' : 'button--outline'" @click="completeQuestion">
            <svg class="icon icon-check" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="caption">Clore la question</span>
          </button>

          <button class="button--danger button-delete" type="button" @click="deleteQuestionConfirm()" v-if="user_is_admin || user_is_coordinator">
            <svg viewBox="0 0 16 18" xmlns="http://www.w3.org/2000/svg" width="16" height="18" fill="currentColor" class="icon">
              <path
                fill-rule="evenodd"
                d="M4.96514 0.527208C5.29854 0.189642 5.75073 0 6.22222 0H9.77778C10.2493 0 10.7015 0.189642 11.0349 0.527208C11.3683 0.864773 11.5556 1.32261 11.5556 1.8V3.6H15.1111C15.602 3.6 16 4.00294 16 4.5C16 4.99706 15.602 5.4 15.1111 5.4H15.0499L14.3382 15.4917C14.2903 16.1728 13.9892 16.8104 13.4957 17.2758C13.0021 17.7413 12.3527 18 11.6782 18H4.3218C3.64732 18 2.99789 17.7413 2.50431 17.2758C2.01079 16.8104 1.70975 16.173 1.66181 15.4919L0.950097 5.4H0.888889C0.397969 5.4 0 4.99706 0 4.5C0 4.00294 0.397969 3.6 0.888889 3.6H4.44444V1.8C4.44444 1.32261 4.63175 0.864773 4.96514 0.527208ZM6.22222 3.6H9.77778V1.8H6.22222V3.6ZM2.7324 5.4L3.43508 15.3637C3.45104 15.5908 3.5514 15.8035 3.71593 15.9586C3.88045 16.1138 4.09693 16.2 4.32178 16.2H11.6782C11.9031 16.2 12.1195 16.1138 12.2841 15.9586C12.4486 15.8035 12.5489 15.591 12.5649 15.3639L13.2676 5.4H2.7324ZM6.22222 7.2C6.71314 7.2 7.11111 7.60294 7.11111 8.1V13.5C7.11111 13.9971 6.71314 14.4 6.22222 14.4C5.7313 14.4 5.33333 13.9971 5.33333 13.5V8.1C5.33333 7.60294 5.7313 7.2 6.22222 7.2ZM9.77778 7.2C10.2687 7.2 10.6667 7.60294 10.6667 8.1V13.5C10.6667 13.9971 10.2687 14.4 9.77778 14.4C9.28686 14.4 8.88889 13.9971 8.88889 13.5V8.1C8.88889 7.60294 9.28686 7.2 9.77778 7.2Z"
                clip-rule="evenodd"
              />
            </svg>
            <span class="caption">Supprimer</span>
          </button>
        </div>

        <section class="section--question-details">
          <div class="card shadow rythm-v flex-row">
            <header class="flex-item--100">
              <h3>Détails de la question</h3>
            </header>

            <div class="flex-item--grow rythm-v">
              <p v-if="question.details" v-html="question.details"></p>

              <p v-if="question.patient_age">
                <span class="label">Type de patient&nbsp;:</span>
                {{ question.patient_age }}
              </p>

              <div class="flex-row flex-gap-s">
                <ul role="list" class="question-themes--primary list-inline" v-if="question.primary_themes.length">
                  <li v-for="theme in question.primary_themes" :key="theme.id" :class="themeClass(theme)">
                    {{ theme.name }}
                  </li>
                  <li v-if="question.primary_other_theme" class="tag--theme-other">
                    {{ question.primary_other_theme }}
                  </li>
                </ul>

                <ul role="list" class="question-themes--secondary list-inline" v-if="question.secondary_themes.length">
                  <li v-for="theme in question.secondary_themes" :key="theme.id" :class="themeClass(theme)">
                    {{ theme.name }}
                  </li>
                  <li v-if="question.secondary_other_theme" class="tag--theme-other">
                    {{ question.secondary_other_theme }}
                  </li>
                </ul>
              </div>

              <section class="section--documents" v-if="question.documents.length">
                <h4>Documents</h4>
                <ul>
                  <li v-for="doc in question.documents" :key="doc.id">
                    <a :href="`${API_URL}/file/${doc.hash}?jwt=${userToken}`" target="_blank" rel="noreferrer">{{ doc.filename }}</a>
                  </li>
                </ul>
              </section>
            </div>

            <button class="button--outline-invert" type="button" v-if="(user_is_coordinator || user_is_expert || user_is_expert_ext) && !!question.owner">
              <div class="user-card flex-item" @click="toggleUserDetails">
                <div>
                  <header>{{ question.owner.firstname }} {{ question.owner.lastname }}</header>
                  <footer v-if="question.owner.specialty">{{ question.owner.specialty }}<br />{{ question.owner.city }}, {{ question.owner.country }}</footer>
                </div>
                <picture class="avatar">
                  <img role="img" :src="question.owner.avatar" :alt="`${question.owner.firstname[0]}${question.owner.lastname[0]}`" width="60" height="60" />
                </picture>
              </div>
            </button>
          </div>
        </section>

        <section class="section--reponse-details" v-if="question.references && question.references.length">
          <div class="card shadow rythm-v">
            <header>
              <h3>Références</h3>
            </header>

            <div class="answer-details flex-row">
              <div class="reference">
                <ul>
                  <li v-for="ref in question.references" :key="ref.id">
                    <a class="button--link" :href="`${API_URL}/file/${ref.hash}?jwt=${userToken}`" target="_blank" rel="noreferrer">
                      <svg data-v-29b06b20="" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="22" fill="none" viewBox="0 0 18 22" class="icon icon-file">
                        <path
                          data-v-29b06b20=""
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M0.87868 0.87868C1.44129 0.31607 2.20435 0 3 0H11C11.2652 0 11.5196 0.105357 11.7071 0.292893L17.7071 6.29289C17.8946 6.48043 18 6.73478 18 7V19C18 19.7957 17.6839 20.5587 17.1213 21.1213C16.5587 21.6839 15.7957 22 15 22H3C2.20435 22 1.44129 21.6839 0.87868 21.1213C0.31607 20.5587 0 19.7957 0 19V3C0 2.20435 0.31607 1.44129 0.87868 0.87868ZM3 2C2.73478 2 2.48043 2.10536 2.29289 2.29289C2.10536 2.48043 2 2.73478 2 3V19C2 19.2652 2.10536 19.5196 2.29289 19.7071C2.48043 19.8946 2.73478 20 3 20H15C15.2652 20 15.5196 19.8946 15.7071 19.7071C15.8946 19.5196 16 19.2652 16 19V7.41421L10.5858 2H3Z"
                          clip-rule="evenodd"
                        ></path>
                        <path
                          data-v-29b06b20=""
                          fill="currentColor"
                          fill-rule="evenodd"
                          d="M11 0C11.5523 0 12 .447715 12 1V6H17C17.5523 6 18 6.44772 18 7 18 7.55228 17.5523 8 17 8H11C10.4477 8 10 7.55228 10 7V1C10 .447715 10.4477 0 11 0zM4 12C4 11.4477 4.44772 11 5 11H13C13.5523 11 14 11.4477 14 12 14 12.5523 13.5523 13 13 13H5C4.44772 13 4 12.5523 4 12zM4 16C4 15.4477 4.44772 15 5 15H13C13.5523 15 14 15.4477 14 16 14 16.5523 13.5523 17 13 17H5C4.44772 17 4 16.5523 4 16zM4 8C4 7.44772 4.44772 7 5 7H7C7.55228 7 8 7.44772 8 8 8 8.55228 7.55228 9 7 9H5C4.44772 9 4 8.55228 4 8z"
                          clip-rule="evenodd"
                        ></path></svg
                      ><span class="caption">{{ ref.filename }}</span></a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        <section class="section--expert-chat">
          <div class="card shadow rythm-v">
            <header>
              <h3 v-if="!user_is_expert_ext && !user_is_expert && !user_is_coordinator">
                Dialogue avec l'expert
              </h3>
              <h3 v-else>Dialogue avec le médecin</h3>
            </header>

            <answers-chat v-if="answers.length" class="section--chat-history" :user="user" :question="question" :answers="answers"></answers-chat>

            <p v-else>
              <em v-if="user_is_assigned">Le médecin est en attente de votre réponse.</em>
              <em v-else>En attente d'une réponse de l'expert.</em>
            </p>
          </div>
        </section>

        <!-- Réponse du médecin -->
        <div class="question-response card shadow" v-if="isEditable && user_is_regular">
          <div class="ansnwer-info flex-row flex-center-v" v-show="!responseEditor.isVisible">
            <div class="info">
              <svg class="icon icon-info" role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22">
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11C20 6.02944 15.9706 2 11 2ZM0 11C0 4.92487 4.92487 0 11 0C17.0751 0 22 4.92487 22 11C22 17.0751 17.0751 22 11 22C4.92487 22 0 17.0751 0 11Z"
                  clip-rule="evenodd"
                />
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M11 10C11.5523 10 12 10.4477 12 11V15C12 15.5523 11.5523 16 11 16C10.4477 16 10 15.5523 10 15V11C10 10.4477 10.4477 10 11 10Z"
                  clip-rule="evenodd"
                />
                <path fill="currentColor" d="M12 7C12 7.55228 11.5523 8 11 8C10.4477 8 10 7.55228 10 7C10 6.44772 10.4477 6 11 6C11.5523 6 12 6.44772 12 7Z" />
              </svg>
              <p>
                Si cette réponse vous convient, vous pouvez
                <strong>clore la question</strong>. Sinon, vous pouvez <strong>répondre à l'expert</strong> pour demander des précisions.
              </p>
            </div>
            <div class="answer-controls flex-row flex-center-v">
              <button type="button" class="button--secondary" @click="responseEditor.isVisible = !responseEditor.isVisible">
                <svg role="img" class="icon icon-more" xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="none" viewBox="0 0 14 14">
                  <path
                    fill="#fff"
                    fill-rule="evenodd"
                    d="M7.875 0.875C7.875 0.391751 7.48325 0 7 0C6.51675 0 6.125 0.391751 6.125 0.875V6.125H0.875C0.391751 6.125 0 6.51675 0 7C0 7.48325 0.391751 7.875 0.875 7.875H6.125V13.125C6.125 13.6082 6.51675 14 7 14C7.48325 14 7.875 13.6082 7.875 13.125V7.875H13.125C13.6082 7.875 14 7.48325 14 7C14 6.51675 13.6082 6.125 13.125 6.125H7.875V0.875Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="caption">Répondre à l'expert</span>
              </button>

              <button v-if="question.status !== 'complete' && this.answers.length" type="button" class="button--primary" @click="completeQuestion">
                <svg class="icon icon-check" role="img" xmlns="http://www.w3.org/2000/svg" width="18" height="13" fill="none" viewBox="0 0 18 13">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M17.7071 0.292893C18.0976 0.683417 18.0976 1.31658 17.7071 1.70711L6.70711 12.7071C6.31658 13.0976 5.68342 13.0976 5.29289 12.7071L0.292893 7.70711C-0.0976311 7.31658 -0.0976311 6.68342 0.292893 6.29289C0.683417 5.90237 1.31658 5.90237 1.70711 6.29289L6 10.5858L16.2929 0.292893C16.6834 -0.0976311 17.3166 -0.0976311 17.7071 0.292893Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="caption">Clore la question</span>
              </button>
            </div>
          </div>

          <form @submit.prevent="sendAnswer" v-show="responseEditor.isVisible">
            <label for="answer"><strong>Votre réponse&nbsp;:</strong></label>
            <vue-editor id="responseEditor" v-model="responseEditor.content" :editor-toolbar="responseEditor.toolbar" />
            <div class="inline-buttons">
              <button type="button" class="button--outline" @click="responseEditor.isVisible = !responseEditor.isVisible">
                <svg role="img" class="icon icon-close" xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="none" viewBox="0 0 12 12">
                  <path
                    fill="currentColor"
                    d="M11.7046 1.72153C12.0985 1.3277 12.0985 0.68919 11.7046 0.295367C11.3108 -0.0984557 10.6723 -0.0984557 10.2785 0.295367L6 4.57384L1.72153 0.295367C1.3277 -0.0984557 0.68919 -0.0984557 0.295367 0.295367C-0.0984555 0.68919 -0.0984555 1.3277 0.295367 1.72153L4.57384 6L0.295367 10.2785C-0.0984559 10.6723 -0.0984555 11.3108 0.295367 11.7046C0.68919 12.0985 1.3277 12.0985 1.72153 11.7046L6 7.42616L10.2785 11.7046C10.6723 12.0985 11.3108 12.0985 11.7046 11.7046C12.0985 11.3108 12.0985 10.6723 11.7046 10.2785L7.42616 6L11.7046 1.72153Z"
                  />
                </svg>
                <span class="caption">Annuler</span>
              </button>
              <button type="submit" class="button--primary" :disabled="isSubmitDisabled">
                <svg role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22" class="icon">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M21.7071 0.292921C22.0976 0.683445 22.0976 1.31661 21.7071 1.70713L10.7071 12.7071C10.3166 13.0977 9.68342 13.0977 9.2929 12.7071C8.90237 12.3166 8.90237 11.6834 9.2929 11.2929L20.2929 0.292921C20.6834 -0.0976032 21.3166 -0.0976032 21.7071 0.292921Z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M21.7071 0.292921C21.9787 0.564559 22.0708 0.967791 21.9439 1.33038L14.9439 21.3304C14.8084 21.7174 14.4504 21.9825 14.0408 21.9992C13.6311 22.0159 13.2527 21.7808 13.0862 21.4062L9.2424 12.7576L0.593867 8.91384C0.219186 8.74731 -0.0158775 8.36894 0.000836285 7.95926C0.0175501 7.54959 0.282654 7.19162 0.669655 7.05617L20.6697 0.0561695C21.0322 -0.0707359 21.4355 0.0212832 21.7071 0.292921ZM3.71108 8.11063L10.4061 11.0862C10.6324 11.1868 10.8133 11.3677 10.9138 11.5939L13.8894 18.289L19.37 2.63L3.71108 8.11063Z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="caption">Répondre à l'expert</span>
              </button>
            </div>
          </form>
        </div>

        <!-- Réponse experts -->
        <div class="question-response card shadow" v-if="isEditable && (user_is_expert || user_is_expert_ext || user_is_coordinator)">
          <form @submit.prevent="sendAnswer">
            <label for="answer">
              <strong>Votre réponse&nbsp;:</strong>
            </label>
            <vue-editor id="responseEditor" v-model="responseEditor.content" :editor-toolbar="responseEditor.toolbar" @text-change="handleUpdateDraft" />

            <div class="inline-buttons">
              <button type="button" class="button--secondary" @click="toggleRefsUploadModal">
                <svg role="img" class="icon icon-upload" xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none" viewBox="0 0 20 20">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M1 12C1.55228 12 2 12.4477 2 13V17C2 17.2652 2.10536 17.5196 2.29289 17.7071 2.48043 17.8946 2.73478 18 3 18H17C17.2652 18 17.5196 17.8946 17.7071 17.7071 17.8946 17.5196 18 17.2652 18 17V13C18 12.4477 18.4477 12 19 12 19.5523 12 20 12.4477 20 13V17C20 17.7957 19.6839 18.5587 19.1213 19.1213 18.5587 19.6839 17.7957 20 17 20H3C2.20435 20 1.44129 19.6839.87868 19.1213.31607 18.5587 0 17.7956 0 17V13C0 12.4477.447715 12 1 12zM9.29289.292893C9.68342-.0976311 10.3166-.0976311 10.7071.292893L15.7071 5.29289C16.0976 5.68342 16.0976 6.31658 15.7071 6.70711 15.3166 7.09763 14.6834 7.09763 14.2929 6.70711L10 2.41421 5.70711 6.70711C5.31658 7.09763 4.68342 7.09763 4.29289 6.70711 3.90237 6.31658 3.90237 5.68342 4.29289 5.29289L9.29289.292893z"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M10 2.98023e-08C10.5523 2.98023e-08 11 0.447715 11 1V13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13V1C9 0.447715 9.44772 2.98023e-08 10 2.98023e-08Z"
                    clip-rule="evenodd"
                  />
                </svg>
                <span class="caption">Ajouter des références</span>
              </button>

              <button type="submit" class="button--primary" :disabled="isSubmitDisabled">
                <svg role="img" xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="none" viewBox="0 0 22 22" class="icon">
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M21.7071 0.292921C22.0976 0.683445 22.0976 1.31661 21.7071 1.70713L10.7071 12.7071C10.3166 13.0977 9.68342 13.0977 9.2929 12.7071C8.90237 12.3166 8.90237 11.6834 9.2929 11.2929L20.2929 0.292921C20.6834 -0.0976032 21.3166 -0.0976032 21.7071 0.292921Z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill="currentColor"
                    fill-rule="evenodd"
                    d="M21.7071 0.292921C21.9787 0.564559 22.0708 0.967791 21.9439 1.33038L14.9439 21.3304C14.8084 21.7174 14.4504 21.9825 14.0408 21.9992C13.6311 22.0159 13.2527 21.7808 13.0862 21.4062L9.2424 12.7576L0.593867 8.91384C0.219186 8.74731 -0.0158775 8.36894 0.000836285 7.95926C0.0175501 7.54959 0.282654 7.19162 0.669655 7.05617L20.6697 0.0561695C21.0322 -0.0707359 21.4355 0.0212832 21.7071 0.292921ZM3.71108 8.11063L10.4061 11.0862C10.6324 11.1868 10.8133 11.3677 10.9138 11.5939L13.8894 18.289L19.37 2.63L3.71108 8.11063Z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
                <span class="caption">Envoyer la réponse au médecin</span>
              </button>
            </div>
          </form>
        </div>

        <!--Liste des liens -->
        <section class="section--links" v-if="linkList">
          <div class="card shadow  rythm-v">
            <header>
              <h3>Sites de référence</h3>
            </header>
            <div class="links flex-row">
              <div class="input-group select-default-links flex-item--50" v-if="user_is_expert || user_is_expert_ext || user_is_coordinator">
                <label for="select-default-links">Sites par défaut</label>
                <p class="hint">Tous les sites par défaut seront affichés si aucun lien n'est associé à la question.</p>
                <multiselect
                  v-if="user_is_expert || user_is_expert_ext || user_is_coordinator"
                  name="select-default-links"
                  v-model="newDefaultLinks"
                  label="label"
                  :options="defaultLinks"
                  :close-on-select="false"
                  placeholder="Choisissez parmi les sites par défaut"
                  :multiple="true"
                  track-by="link_id"
                  @close="addNewDefaultLinks"
                  :searchable="true"
                  :preselect-first="false"
                  :clear-on-select="false"
                  :preserve-search="false"
                  :internal-search="true"
                  selectLabel=""
                  selectedLabel="Sélectionné"
                  deselectLabel="Supprimer"
                  tag-placeholder="Ajouter"
                >
                </multiselect>
              </div>

              <div class="flex-item rythm-v" style="width:100%">
                <form id="fromAddNewLink" @submit.prevent="addNewLink" class="flex-row flex-align-bottom" v-if="user_is_expert || user_is_expert_ext || user_is_coordinator">
                  <div class="flex-item--25">
                    <div class="input-group">
                      <label for="label">Texte du lien</label>
                      <input type="text" name="label" id="new-link-label" v-model="newLink.label" required />
                    </div>
                  </div>

                  <div class="flex-item--25">
                    <div class="input-group">
                      <label for="url">Adresse</label>
                      <input type="url" name="url" id="new-link-url" v-model="newLink.url" required />
                    </div>
                  </div>

                  <div class="flex-item--25">
                    <button class="button--primary" type="submit">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        class="feather feather-plus"
                      >
                        <line x1="12" y1="5" x2="12" y2="19"></line>
                        <line x1="5" y1="12" x2="19" y2="12"></line>
                      </svg>

                      <span class="caption">Ajouter</span>
                    </button>
                  </div>
                </form>

                <div>
                  <ul class="links-list">
                    <li v-for="(link, index) in linkList" :key="`link_${index}`">
                      <div class="link-card">
                        <header>{{ link.label }}</header>
                        <a :href="link.url" target="_blank">{{ link.url }}</a>
                        <button class="button--link button-delete-link" title="Supprimer le lien" @click="removeQuestionLink(link.link_id)" v-if="!user_is_regular && user_is_assigned">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="black"
                            stroke-width="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            class="feather feather-trash-2"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>

    <!--
		<expert-response-modal
			v-if="question && isEditable"
			:open="modalExpertResponseVisible"
			:user="user"
			:question="question"
			@modalClose="modalClose"
			@submit="sendAnswer"
		/>
		-->

    <mailto-modal :open="modalMailtoVisible" :users="experts" :question="this.question" @modalClose="modalClose" />

    <assign-expert-modal :open="modalAssignUserVisible" :users="experts" :question-id="this.questionID" @modalClose="modalClose" @click="assignUser" />

    <user-details :user-id="question.owner.id" :open="modalUserDetailsVisible" @modalClose="modalClose" />

    <refs-upload-modal :question="question" :user="user" :open="modalRefsUploadVisible" @modalClose="modalClose" @deleteReference="deleteReference" @refreshQuestion="getQuestionData" />
  </article>
</template>

<style lang="scss" scoped>
@import "src/scss/question.scss";
@import "src/scss/06-components/components.toolbar";
@import "~@/scss/06-components/vue-multiselect";

[class*="ql-align-"] img {
  display: inline;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}

.quillWrapper .ql-container {
  height: 20rem;
}

.chat-message + .chat-message {
  margin-top: 2rem;
}

.ansnwer-info {
  & > * + * {
    margin-left: 1rem;
  }
  .info {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 40rem;
    padding: 0.5rem;
    .icon {
      color: #6fcf97;
      margin-right: 1rem;
      float: left;
    }
    p {
      margin-top: 0;
      font-weight: 500;
      color: $color-black;
    }
  }
}

.answer-controls {
  justify-content: flex-end;
  & > * + * {
    margin-left: 1rem;
  }
}

.inline-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  & > * {
    margin: 0.5rem;
  }
}

@media all and (max-width: 768px) {
  .ansnwer-info > * + * {
    margin-left: 1rem;
  }
  .answer-controls {
    margin: 1rem 0 0 0;
    justify-content: center;
    flex-basis: 100%;
  }
  .answer-controls > * {
    margin: 0.5rem;
  }
  .ansnwer-info .info {
    flex-basis: 100%;
  }

  .inline-buttons {
    justify-content: center;
  }
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<script>
import { HTTP } from "../http-common.js";
import { slugify } from "../libs/helpers.js";
import { EventBus } from "../event-bus.js";
import { VueEditor } from "vue2-editor";

// IndexedDB
import idb from "@/indexedDB.js";

import AnswersChat from "@/components/AnswersChat";
import expertResponseDetailsModal from "@/components/expertResponseDetailsModal";
import AssignExpertModal from "@/components/AssignExpertModal.vue";
import UserDetails from "@/components/UserDetails.vue";
import RefsUploadModal from "@/components/RefsUploadModal.vue";

import MailtoModal from "@/components/MailtoModal.vue";
import MailtoExperts from "@/components/MailtoExperts.vue";
import { log } from "logrocket";

//Multiselect
import Multiselect from "vue-multiselect";

// Component
export default {
  name: "Question",
  props: ["questionID"],
  components: {
    AnswersChat,
    AssignExpertModal,
    expertResponseDetailsModal,
    VueEditor,
    UserDetails,
    RefsUploadModal,
    MailtoModal,
    MailtoExperts,
    Multiselect,
  },

  filters: {
    // Pluralize filter
    pluralize: function(value, term) {
      return value > 1 ? `${term}s` : term;
    },
  },

  data: function() {
    return {
      API_URL: HTTP.defaults.baseURL,
      userToken: this.$store.state.userToken,
      question: null,
      answerDraft: null,
      answers: [],
      experts: [],

      // Liens
      links: [],
      defaultLinks: [],
      newLink: {
        label: "",
        url: "",
      },
      newDefaultLinks: [],

      // Submit status
      isSubmitDisabled: false,

      // Modals status
      modalAssignUserVisible: false,
      modalUserDetailsVisible: false,
      modalRefsUploadVisible: false,
      modalMailtoVisible: false,

      // Response
      responseData: {
        answerText: "",
        ref: "",
        refFilename: "",
      },

      editorDebounce: null,
      responseEditor: {
        isVisible: false,
        content: "",
        toolbar: [
          ["bold", "italic", "underline", "strike"], // toggled buttons
          [{ list: "ordered" }, { list: "bullet" }],
          [{ script: "sub" }, { script: "super" }], // superscript/subscript
          [{ align: "" }, { align: "center" }, { align: "right" }, { align: "justify" }],
          ["link", "image"],
          ["clean"],
          // [{header: [false, 2, 3, 4, 5, 6]}],
          // [{align: []}],
          // ["blockquote", "code-block"],
          // [{header: 1}, {header: 2}], // custom button values
          // [{indent: "-1"}, {indent: "+1"}], // outdent/indent
          // [{direction: "rtl"}], // text direction
          // [{size: ["small", false, "large", "huge"]}], // custom dropdown
          // [{color: []}, {background: []}], // dropdown with defaults from theme
          // [{font: []}],
        ],
      },

      responseThanks: "<br><br><br><br><br><br>Nous vous remercions d'avoir utilisé la plateforme Infovac et espérons vous revoir bientôt.",

      /*
      responseThanks: [
        "<br><br><br><br><br><br>Nous vous remercions d'avoir utilisé la plateforme Infovac et espérons vous revoir bientôt.",
        "<br><strong>Informations complémentaires :</strong><br>",
        "<ul>",
        '<li><a target="_blank" href="https://www.infovac.fr/?view=article&amp;id=847&amp;catid=45">Covid-19, l’essentiel (Infovac)</a></li>',
        '<li><a target="_blank" href="https://afpa.org/dossier/dossier-coronavirus/">L’actualité Covid-19 (AFPA)</a></li>',
        '<li><a target="_blank" href="https://www.infovac.fr/actualites/bulletin-n-14-supplement-decembre-2020">Abécédaire Covid-19</a></li>',
        '<li><a target="_blank" href="https://covidtracker.fr/vaccintracker/">Covid/Vaccin Tracker</a></li>',
        '<li><a target="_blank" href="https://www.infectiologie.com/fr/actualites/newsletter-spilf-infovac_-n.html">Newsletter SPILF/Infovac</a></li>',
        '<li><a target="_blank" href="https://www.infovac.fr/vaccination/fiches-maladies">Fiches Maladies et Vaccins</a></li>',
        '<li><a target="_blank" href="https://www.infovac.fr/vaccins/disponibilite-des-vaccins">Disponibilité des vaccins</a></li>',
        '<li><a target="_blank" href="https://www.infovac.fr/l-hesitation-vaccinale">Hésitation vaccinale</a></li>',
        '<li><a target="_blank" href="https://www.infovac.fr/docman-marc/public/fiches/1569-2-rattrapage-des-vaccinations-chez-l-enfant-et-l-adulte-2019/file">Rattrapage des vaccinations chez l’enfant et l’adulte</a></li>',
        "</ul>",
      ].join(""),
			*/

      statusCaption: {
        draft: "Brouillon",
        pending: "En attente de réponse",
        ongoing: "Réponse disponible",
        complete: "Terminé",
      },

      typeCaption: {
        general: "Question générale",
        patient: "Question relative à un patient",
      },
    };
  },

  computed: {
    linkList: function() {
      if (!this.links.length) return this.defaultLinks;
      return this.links;
    },

    // Urgent questions
    isQuestionUrgent: function() {
      if (!this.question) return false;
      return this.question.is_urgent && !!parseInt(this.question.is_urgent);
    },

    // Starred question
    isQuestionStarred: function() {
      if (!this.question) return false;
      return this.question.is_starred && !!parseInt(this.question.is_starred);
    },

    // Current user data
    user: function() {
      return this.$store.state.userData;
    },

    // Is the user an admin? (role 4)
    user_is_admin: function() {
      return this.user.roles.indexOf(4) !== -1;
    },

    // Is the user a coordinator? (role 3)
    user_is_coordinator: function() {
      return this.user.roles.indexOf(3) !== -1;
    },

    // Is the user an expert? (role 2)
    user_is_expert: function() {
      return this.user.roles.indexOf(2) !== -1;
    },

    // Is the user an expert extended? (role 5)
    user_is_expert_ext: function() {
      return this.user.roles.indexOf(5) !== -1;
    },

    // Is a regular user?
    user_is_regular: function() {
      let r = this.user.roles.reduce((acc, val, i, roles) => acc + parseInt(val));
      return r < 2;
    },

    // Is the question assigned to the current user?
    user_is_assigned: function() {
      return this.question.expert_id === this.user.id;
    },

    // Complete question button
    canCloseQuestion: function() {
      if (this.question.status == "complete") return false;

      // Allow coordinators to close any questions
      if (!!this.user_is_coordinator) {
        return true;
      }

      // The user IS an expert, the question is assigned to the user
      if ((!!this.user_is_expert || !!this.user_is_expert_ext) && this.user_is_assigned) {
        return true;
      }

      // The user is NOT an expert and an expert response IS available
      if (!this.user_is_expert && !this.user_is_expert_ext && !this.user_is_coordinator && this.question.status === "ongoing") return true;

      // Else, return false
      return false;
    },

    // Display chat editor
    isEditable: function() {
      if (this.user_is_regular) {
        // The user is NOT an expert and an expert response IS available
        if (this.question.status === "ongoing") return true;
        return false;
      } else {
        // The user IS an expert (or coordinator), the question is assigned to the user
        // && this.question.status === "pending"
        if (this.user_is_assigned) return true;
        return false;
      }

      return false;
    },
  },

  methods: {
    /**
     * Mark question as read (for current user)
     */
    updateReadStatus() {
      let payload = { questionID: this.questionID };
      this.$store.dispatch("PUT_QUESTION_READ_STATUS", payload);
    },

    deleteReference: function(hash) {
      this.$store
        .dispatch("DELETE_DOCUMENT", hash)
        .then((res) => {
          // Update the question
          this.getQuestionData();

          // Analytics
          // EventBus.$emit("trackEvent", "Références Question", `Référence supprimée pour question ${this.question.id} par userID ${this.user.id}`);
        })
        .catch((error) => {
          // EventBus.$emit("trackEvent", "Références Question", `Erreur de suppression de ${hash} pour questionID:${this.question.id} et userID:${this.user.id}`);
          console.error("DELETE_DOCUMENT", error);
        });
    },

    toggleUserDetails() {
      this.modalUserDetailsVisible = !this.modalUserDetailsVisible;
    },

    // Init editor content
    setEditorContent() {
      if (!this.user_is_regular) {
        this.responseEditor.content = this.responseThanks;
      } else {
        this.responseEditor.content = "";
      }
    },

    // assign user modal
    assignUserModal: function() {
      // Load experts then show modal
      this.$store
        .dispatch("GET_EXPERT_USERS", {})
        .then((experts) => {
          this.experts = experts;
          this.modalAssignUserVisible = true;
        })
        .catch((error) => {
          console.error("GET_EXPERT_USERS", error);
        });
    },

    assignUser: function(user) {
      let payload = { questionID: this.questionID, userID: user.id };

      this.$store
        .dispatch("POST_ASSIGN_EXPERT", payload)
        .then((res) => {
          // Toast
          let expert = this.experts.find((e) => e.id === user.id);
          let expertName = expert ? `${expert.firstname} ${expert.lastname}` : "";

          this.$toasted.global.appSuccess({
            message: `La question a été affectée à <strong>${expertName}</strong>.`,
          });

          // Update the question
          this.getQuestionData();
          this.modalClose();

          // Analytics
          // EventBus.$emit("trackEvent", "Affectation", `Utilisateur ${user.id} affecté à questionID ${this.modalAssignQuestionID} par userID ${this.user.id}`);
        })
        .catch((error) => {
          console.error("POST_ASSIGN_EXPERT", error);
        });
    },

    resetFileInput: function() {
      let fileInput = this.$refs.ref.files[0];
      fileInput.value = "";
    },

    // set ref input file
    setRefFiles: function(e) {
      let fileInput = e.target;
      let errors = false;

      if (fileInput.files.length) {
        fileInput.files.forEach((f) => {
          // file type
          if (f.type !== "application/pdf") {
            this.resetFileInput();
            errors = true;
            this.$toasted.global.appError({
              message: "Le fichier n'est pas un PDF.",
            });
          }

          // file weight
          if (typeof FileReader !== "undefined") {
            let size = f.size;
            if (size > 20000000) {
              errors = true;
              this.resetFileInput();
              this.$toasted.global.appError({
                message: "Le poids du fichier ne doit pas dépasser 20 Mo.",
              });
            }
          }

          // display filename
          let fileCount = fileInput.files.length;
          let caption = fileCount > 1 ? `${fileCount} fichiers` : f.name;
          if (!errors) this.responseData.refFilename = caption;
        });
      }
    },

    // toggle refs modal
    toggleRefsUploadModal: function(event) {
      this.modalRefsUploadVisible = !this.modalRefsUploadVisible;
    },

    // toggle experts mailto modal
    toggleMailtoModal: function(event) {
      // Close modal
      if (this.modalMailtoVisible === true) {
        this.modalMailtoVisible = false;
        return;
      }

      // Load experts then show modal
      this.$store
        .dispatch("GET_EXPERT_USERS", {})
        .then((experts) => {
          this.experts = experts;
          this.modalMailtoVisible = true;
        })
        .catch((error) => {
          console.error("GET_EXPERT_USERS", error);
        });
    },

    // close modal
    modalClose: function(event) {
      this.modalAssignUserVisible = false;
      this.modalUserDetailsVisible = false;
      this.modalRefsUploadVisible = false;
      this.modalMailtoVisible = false;
    },

    // Complete question
    completeQuestion: function() {
      let msg =
        '<div class="message-wrapper"><strong>Clore cette question&nbsp;?</strong> <br>Aucune modification ne pourra y être apportée. <br>Il vous sera ensuite possible de laisser un message à l’expert.</div>';

      if (!this.user_is_regular) {
        msg = '<div class="message-wrapper"><strong>Clore cette question&nbsp;?</strong> <br>Aucune modification ne pourra y être apportée par le médecin demandeur.</div>';
      }

      this.$toasted.show(msg, {
        containerClass: "toasted",
        theme: "primary",
        position: "center",
        duration: null,
        closeOnSwipe: false,
        action: [
          {
            text: "Annuler",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              return;
            },
          },
          {
            text: "Confirmer",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);

              // change question status
              let questionID = this.questionID;
              this.$store
                .dispatch("COMPLETE_QUESTION", questionID)
                .then(() => {
                  // EventBus.$emit("trackEvent", "Question Close", `Question ${questionID} close par userID ${this.user.id}`);

                  this.$router.push("/dashboard");
                })
                .catch((error) => {
                  // EventBus.$emit("trackEvent", "Question Close", `Erreur, impossible de clore la ${questionID} par userID ${this.user.id}`);
                  console.error("COMPLETE_QUESTION", error);
                });
            },
          },
        ],
      });
    },

    // Post answer and reference
    sendAnswer: function() {
      // Disable submit
      this.isSubmitDisabled = true;

      // If empty or default thanks message (tags stripped)
      if (!this.responseEditor.content || this.responseEditor.content.replace(/(<([^>]+)>)/gi, "") == this.responseThanks.replace(/(<([^>]+)>)/gi, "")) {
        this.$toasted.global.appError({
          message: "Vous ne pouvez pas envoyer une réponse vide.",
        });

        // Enable submit
        setTimeout(() => (this.isSubmitDisabled = false), 300);
        return false;
      }

      // Update answer
      this.responseData.answerText = this.responseEditor.content;

      // Save answer
      let questionID = this.questionID;
      let payload = {
        details: this.responseData.answerText,
      };

      this.$store
        .dispatch("POST_ANSWER", { questionID, payload })
        .then((response) => {
          // EventBus.$emit("trackEvent", "Réponse Question", `Envoyée pour question ${questionID} par userID ${this.user.id}`);

          this.getQuestionData();
          this.getAnswers();
          this.$toasted.global.appSuccess({
            message: "Votre réponse est envoyée",
          });

          // Delete answer draft
          this.deleteAnswerDraft();

          // Reset expert editor
          if (!this.user_is_regular) this.setEditorContent();

          setTimeout(() => (this.isSubmitDisabled = false), 300);
        })
        .catch((error) => {
          this.$toasted.global.appError({
            message: "Une erreur est survenue lors de l'envoi de votre réponse.<br>Veuillez nous contacter pour résoudre le problème.",
          });

          // enable submit
          setTimeout(() => (this.isSubmitDisabled = false), 300);

          // EventBus.$emit("trackEvent", "Réponse Question", `Erreur enregistrement pour question ${questionID} par userID ${this.user.id}`);
        });
    },

    // Retrieve chat
    getAnswers: function() {
      this.$store
        .dispatch("GET_ANSWERS", this.questionID)
        .then((answers) => {
          this.answers = answers;
        })
        .catch((error) => {
          console.error("GET_ANSWERS", error);
        });
    },

    // confirm delete question
    confirmPrompt: function(message, callbackTrue = null, callbackFalse = null) {
      this.$toasted.show(message, {
        containerClass: "toasted",
        theme: "primary",
        position: "center",
        duration: null,
        closeOnSwipe: false,
        action: [
          {
            text: "Annuler",
            onClick: (e, toastObject) => {
              if (typeof callbackFalse === "function") callbackFalse(e, toastObject);
              toastObject.goAway(0);
              return;
            },
          },
          {
            text: "Confirmer",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              if (typeof callbackTrue === "function") callbackTrue(e, toastObject);
              return;
            },
          },
        ],
      });
    },

    deleteQuestion: function() {
      this.$store
        .dispatch("DELETE_QUESTION", this.questionID)
        .then((success) => {
          if (!success) {
            // EventBus.$emit("trackEvent", "Suppression Question", `Erreur suppression question ${this.questionID} pour userID ${this.user.id}`);
            this.$toasted.global.appError({
              message: "<strong>Impossible de supprimer la question&nbsp;!",
            });
          } else {
            // EventBus.$emit("trackEvent", "Suppression Question", `Question ${this.questionID} supprimée par userID ${this.user.id}`);
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          // EventBus.$emit("trackEvent", "Suppression Question", `Erreur suppression question ${this.questionID} pour userID ${this.user.id}`);
          console.error("DELETE_QUESTION", error);
        });
    },

    // Delete question confirmation
    deleteQuestionConfirm: function() {
      let self = this;
      this.confirmPrompt(`<div class="message-wrapper"><strong>Supprimer la question №&nbsp;${this.questionID}&nbsp;?</strong> <br><em>${this.question.title}</em></div>`, self.deleteQuestion);
    },

    getQuestionData: function() {
      this.$store
        .dispatch("GET_QUESTION_DATA", this.questionID)
        .then((response) => {
          if (null !== response) {
            this.question = response;
            this.loadAnswerDraft();
            if (!this.user_is_regular) this.updateReadStatus();
          } else {
            this.$toasted.global.appInfo({
              message: `Cette question a été supprimée.`,
            });
            this.$router.push("/dashboard");
          }
        })
        .catch((error) => {
          console.error("GET_QUESTION_DATA", error);
          // eventHub.$emit("network-error", error.response);
        });
    },

    // set the theme tag classes
    themeClass: function(theme) {
      let classAttr = `tag--theme-${this.slug(theme.name)}`;
      if (parseInt(theme.parent_id) === 0) classAttr = `${classAttr} is-root-theme`;
      return classAttr;
    },

    // Helpers.slugify: Create slug from string
    slug: slugify,

    handleUpdateDraft(event) {
      // Abort if the answer is empty (or only contains the thanks message)
      if (!this.responseEditor.content || this.responseEditor.content.replace(/(<([^>]+)>)/gi, "") == this.responseThanks.replace(/(<([^>]+)>)/gi, "")) return;

      // debounce
      clearTimeout(this.editorDebounce);
      this.editorDebounce = setTimeout(async () => {
        // insert/update draft
        await this.saveAnswerDraft(this.user.id, this.questionID, {
          answer: this.responseEditor.content,
        });
      }, 300);
    },

    /**
     * Init database
     *
     * @return void
     */
    async getDb() {
      return new Promise((resolve, reject) => {
        let request = window.indexedDB.open("infovacIDB", 1);

        request.onerror = (e) => {
          console.log("Error opening db", e);
          reject("Error");
        };

        request.onsuccess = (e) => {
          // Return the database object
          resolve(e.target.result);
        };

        request.onupgradeneeded = (e) => {
          // configure database
          let db = e.target.result;
          let objectStore = db.createObjectStore("answerDrafts", { autoIncrement: true, keyPath: "id" });
          let index = objectStore.createIndex("user_draft", ["uid", "qid"], { unique: true });
        };
      });
    },

    /**
     * Load user answer draft (if any)
     *
     * @return void
     */
    async loadAnswerDraft() {
      // Answer draft?
      if (this.user_is_regular) return;
      if (!this.question || this.question.status !== "pending") return;

      this.db = await this.getDb();
      this.answerDraft = await this.getAnswerDraft(this.user.id, this.questionID);
      if (this.answerDraft && this.answerDraft.draft && this.answerDraft.draft.answer) {
        this.responseEditor.content = this.answerDraft.draft.answer;
      }
    },

    /**
     * Update/Insert user question draft
     *
     * @param integer uid User ID
     * @param {integer} qid Question ID
     * @param {object} draft Draft data
     * @return Promise
     */
    async saveAnswerDraft(uid, qid, data) {
      return new Promise((resolve, reject) => {
        let transaction = this.db.transaction("answerDrafts", "readwrite"); // read-only by default
        let store = transaction.objectStore("answerDrafts");
        let index = store.index("user_draft");
        let request = index.get([uid, qid]);

        request.onsuccess = () => {
          const answerDraft = request.result;

          if (!!answerDraft) {
            // Update
            answerDraft.draft = data;
            const updateRequest = store.put(answerDraft);

            updateRequest.onsuccess = () => {
              let answerDraftID = updateRequest.result;
              resolve(answerDraftID);
            };

            updateRequest.onerror = (e) => {
              resolve(null);
            };
          } else {
            // Insert
            const insertRequest = store.put({ uid: uid, qid: qid, draft: data });

            insertRequest.onsuccess = () => {
              let answerDraftID = insertRequest.result;
              resolve(answerDraftID);
            };

            insertRequest.onerror = (e) => {
              resolve(null);
            };
          }
        };

        request.onerror = (e) => {
          reject("Error", e);
        };
      });
    },

    /**
     * Delete answer draft
     *
     * @return void
     */
    async deleteAnswerDraft() {
      this.db = await this.getDb();
      let answerDraft = await this.getAnswerDraft(this.user.id, this.questionID);
      if (!answerDraft) return;
      let transaction = this.db.transaction("answerDrafts", "readwrite");
      let store = transaction.objectStore("answerDrafts");
      let request = store.delete(answerDraft.id);
    },

    /**
     * Get answer draft  (if any)
     * Query IndexedDB with the unique indexes uid and qid
     *
     * @param integer uid User ID
     * @param integer qid Question ID
     * @return Promise
     */
    async getAnswerDraft(uid, qid) {
      return new Promise((resolve, reject) => {
        let transaction = this.db.transaction("answerDrafts"); // read-only by default
        let store = transaction.objectStore("answerDrafts");
        let index = store.index("user_draft");
        let request = index.get([uid, qid]);

        request.onsuccess = () => {
          if (!!request.result) resolve(request.result);
          resolve(null);
        };

        request.onerror = (e) => {
          reject("Error", e);
        };
      });
    },
    /**
     * Récupère les liens de la question
     */
    getQuestionLinks: function() {
      this.$store
        .dispatch("GET_QUESTION_LINKS", this.questionID)
        .then((links) => {
          if (links.length === 0) {
            this.links = [...this.defaultLinks];
            console.log("default links");
          } else {
            this.links = links;
          }
        })
        .catch((error) => {
          console.error("GET_QUESTION_LINKS", error);
        });
    },

    /**
     * Récupère la liste des liens par défaut
     */
    getDefaultLink: function() {
      this.$store
        .dispatch("GET_DEFAULT_LINKS", {})
        .then((defaultLinks) => {
          this.defaultLinks = defaultLinks;
        })
        .catch((error) => {
          console.error("GET_DEFAULT_LINKS", error);
        });
    },

    /**
     * Retire un lien de la liste des liens de la question
     *
     * @param integer linkIdToDelete
     */
    removeQuestionLink: function(linkIdToDelete) {
      this.links = this.links.filter((link) => link.link_id != linkIdToDelete);
      this.saveQuestionLink();
    },

    /**
     * Sauvegarde la liste des liens d'une question
     */
    saveQuestionLink: function() {
      let questionId = this.questionID;
      let links = this.links;
      this.$store
        .dispatch("SAVE_QUESTION_LINK", { questionId, links })
        .then((success) => {
          if (success) {
            this.getQuestionLinks();
          } else {
            this.$toasted.global.appError({
              message: "<strong>Impossible d'enregistrer les liens&nbsp;!",
            });
          }
        })
        .catch((error) => {
          console.error("SAVE_QUESTION_LINK", error);
        });
    },

    /**
     * Ajoute un lien à la liste des liens de la question
     */
    addNewLink: function() {
      let newLink = {
        label: this.capitalizeFirstLetter(this.newLink.label),
        url: this.newLink.url,
        is_default: 0,
      };

      this.links.push(newLink);

      this.saveQuestionLink();

      this.newLink = {
        label: "",
        url: "",
      };
    },

    addNewDefaultLinks: function() {
      let newDefaultLinks = this.newDefaultLinks;
      let links = this.links;
      let linksKeys = [];

      // On récupère la liste des id de liens de links
      links.forEach((value) => {
        linksKeys.push(value.link_id);
      });

      // Parmi les nouveau liens par defaut si l'un n'est pas déjà dans links on l'ajoute
      newDefaultLinks.forEach((value) => {
        if (!linksKeys.includes(value.link_id)) {
          links.push(value);
        }
      });

      this.saveQuestionLink();
      this.newDefaultLinks = [];
    },

    //Met une majuscule à la première lettre d'une phrase
    capitalizeFirstLetter: function(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },

    async init() {
      if (this.questionID > 0) {
        await this.getQuestionData();
        await this.getAnswers();
        await this.getDefaultLink();
        await this.getQuestionLinks();
      }
      // Init editor
      this.setEditorContent();
    },
  },

  activated() {
    this.init();
  },

  mounted() {
    this.init();
  },
};
</script>

<template>
  <div>
    <div class="chat-message " v-for="answer in answers" :key="answer.id">
      <header class="flex-row flex-center-v flex-align-spaced">
        <div class="user-card" v-if="answer.user">
          <picture :class="user_is_expert || user_is_coordinator ? 'expert-avatar' : 'avatar'">
            <img role="img" :src="answer.user.avatar" :alt="`${answer.user.firstname[0]}${answer.user.lastname[0]}`" width="60" height="60" />
          </picture>
          <div>
            <header>{{ answer.user.firstname }} {{ answer.user.lastname }}</header>
            <footer>{{ answer.user.specialty }}</footer>
          </div>
        </div>

        <small class="date">Le {{ answer.date_modified }}</small>
      </header>
      <div class="message" v-html="answer.details"></div>
    </div>
  </div>
</template>

<style lang="scss">
[class*="ql-align-"] img {
  display: inline;
}

.ql-align-right {
  text-align: right;
}

.ql-align-center {
  text-align: center;
}

.ql-align-justify {
  text-align: justify;
}
</style>

<style lang="scss" scoped>
details {
  text-align: right;
}
.message {
  margin-left: 58px;
  border-left: 2px solid #ccc;
  padding: 0 1em;

  ul,
  p + p {
    margin-top: 0.875rem !important;
  }
}

.expert-avatar {
  img {
    object-fit: contain;
  }
}

.section--chat-history {
  & > * + * {
    margin-top: 3rem;
  }
}

.chat-message {
  & > header {
    margin-bottom: 1rem;
  }
  .date {
    font-style: italic;
    padding-left: 2.5rem;
  }
  .user-card footer {
    text-align: left;
  }
  .user-card > div {
    text-align: left;
  }
}

@media all and (max-width: 768px) {
  .message {
    margin: 1rem 0 0 0;
  }

  .chat-message {
    .user-card {
      flex-basis: 100%;
    }
    .user-card .expert-avatar img {
      width: 2rem;
    }
  }
}
</style>

<script>
export default {
  name: "AnswersChat",
  props: ["user", "question", "answers"],

  computed: {
    // Is the user a coordinator? (role 3)
    user_is_coordinator: function() {
      return this.user.roles.indexOf(3) !== -1;
    },
    // Is the user an expert? (role 2)
    user_is_expert: function() {
      return this.user.roles.indexOf(2) !== -1;
    },
  },
};
</script>

<template>
  <section class="card shadow rythm-v">
    <header class="flex-row flex-center-v flex-nowrap">
      <h3 class="flex-item--100">Demande d’échange entre experts</h3>
    </header>

    <form @submit.prevent="sendEmail">
      <div class="flex-row rythm-v-l">
        <!-- <div class="flex-item--100">
            <div class="input-group rythm-v">
              <label for="experts">Destinataires</label>
              <multiselect
                name="experts"
                id="experts"
                label="name"
                track-by="id"
                :multiple="true"
                :searchable="true"
                :preselect-first="false"
                :close-on-select="false"
                :clear-on-select="false"
                :preserve-search="false"
                :internal-search="true"
                selectLabel=""
                selectedLabel="Sélectionné"
                deselectLabel="Supprimer"
                tag-placeholder="Ajouter"
                placeholder="Rechercher dans la liste"
                v-model="experts"
                :options="mutiselectOptions"
              >
                <span slot="noResult">Aucun résultat…</span>
                <span slot="noOptions">Aucun expert !</span>
              </multiselect>
            </div>
          </div> -->

        <div class="flex-item--100">
          <div class="input-group rythm-v">
            <label for="message"
              >Votre message<br /><span class="text-small"><em>Merci de ne pas partager d'informations personnelles ou données de santé par e-mail.</em></span>
            </label>
            <textarea name="message" id="message" cols="30" rows="4" v-model="emailBody"></textarea>
          </div>
        </div>

        <div class="input-group flex-item--100 flex-row flex-align-right flex-gap">
          <button class="button--outline" type="button" @click="closeModal">Fermer</button>
          <button class="button--primary" type="submit" :disabled="!readyToSend">
            <span class="caption">Envoyer</span>
            <svg class="icon icon-send" role="img" aria-hidden="true" fill="none" height="16" viewBox="0 0 14 16" width="14" xmlns="http://www.w3.org/2000/svg">
              <path
                clip-rule="evenodd"
                d="m13.345 1.64566c.0147-.24055-.1104-.46862-.3232-.58911s-.4768-.112728-.6818.02005l-11.702467 7.57733c-.23165.14999-.340325.4279-.270344.69134.069982.26343.303111.45401.579942.47409l6.632549.48114 3.74122 5.3924c.1561.2251.441.3278.7086.2555.2676-.0722.4587-.3035.4753-.5752zm-5.76338 7.37907-4.64181-.33674 9.03229-5.84841-.6485 10.59532-2.61829-3.77396 1.09391-1.8581c.17915-.3043.07283-.69341-.23746-.8691-.3103-.17569-.70707-.07143-.88622.23287z"
                fill="currentColor"
                fill-rule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>
    </form>
  </section>
</template>

<style lang="scss" scoped>
@import "~@/scss/01-settings/settings.colors";
@import "~@/scss/02-tools/tools.mixins";
@import "~@/scss/06-components/components.modal";
@import "~@/scss/06-components/vue-multiselect";
@import "~@/scss/99-utilities/_utilities.text";

header h2 {
  color: $color-blue;
}

.input-group--controls {
  justify-content: center;
  button {
    margin: 0.5rem 0.5rem 0;
  }
}
</style>

<script>
// import Multiselect from "vue-multiselect";
import { HTTP } from "../http-common.js";

export default {
  name: "MailtoExperts",
  // components: { Multiselect },
  props: ["question", "users"],

  data: function() {
    return {
      // experts: [],
      emailBody: "",
    };
  },

  computed: {
    readyToSend() {
      return this.mail.body.length > 4; //&& this.mail.recipients.length;
    },

    // Format options
    // mutiselectOptions() {
    //   // Filter users
    //   let experts = this.users.filter((el) => {
    //     let isExpert = el.roles.split(",").includes("2");
    //     let isExpertExt = el.roles.split(",").includes("5");
    //     if (isExpert || isExpertExt) return el;
    //   });

    //   // Return options
    //   let options = experts.map((el) => {
    //     return {
    //       id: el.id,
    //       name: `${el.firstname} ${el.lastname}`,
    //     };
    //   });

    //   return options;
    // },

    mail() {
      return {
        questionID: this.question.id,
        category: this.question.primary_themes[0].name,
        body: this.emailBody.trim(),
        // recipients: this.experts.length ? this.experts.map((o) => o["id"]) : [],
      };
    },
  },

  methods: {
    sendEmail: function() {
      let payload = this.mail;

      const self = this;

      HTTP.post("/mailing/experts", payload)
        .then(function(response) {
          const payload = response.data.payload || null;

          self.$toasted.global.appSuccess({
            message: "Demande envoyée !",
          });

          self.closeModal();
        })
        .catch(function(error) {
          self.$toasted.global.appError({
            message: "Erreur lors de l’envoi !!",
          });
        });

      // this.$emit("sendExpertsMail", this.mail);
    },

    closeModal: function() {
      console.log("closeModal");
      this.emailBody = "";
      // this.experts = [];
      this.$emit("modalClose");
    },
  },
};
</script>
